import React, { useCallback, useEffect, useRef } from 'react';
import cls from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { Parser, Player } from 'svga';

import styles from './style.module.scss';

export interface LoadingProps {
  loading: boolean;
  opacity?: number;
  fullscreen?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  maskClassName?: string;
}

const NewLoading = (props: LoadingProps) => {
  const { loading = false, fullscreen, opacity = 1, maskClassName, style = {} } = props;
  const svgaRef = useRef<HTMLCanvasElement>(null);
  const svgaPlayerRef = useRef<Player | null>(null);
  const processingRef = useRef<HTMLCanvasElement>(null);
  const processingPlayerRef = useRef<Player | null>(null);

  const init = useCallback(async () => {
    if (svgaRef.current) {
      const parser = new Parser({ isDisableWebWorker: true });
      const svga = await parser.load('/imgs/pc/loading/loading.svga');
      svgaPlayerRef.current = new Player({ container: svgaRef.current as HTMLCanvasElement });
      await svgaPlayerRef?.current.mount(svga);
    }
  }, []);

  const initProcessing = useCallback(async () => {
    if (processingRef.current) {
      const parser = new Parser({ isDisableWebWorker: true });
      const svga = await parser.load('/imgs/pc/loading/processing.svga');
      processingPlayerRef.current = new Player({ container: processingRef?.current as HTMLCanvasElement });
      await processingPlayerRef.current.mount(svga);
    }
  }, []);

  useEffect(() => {
    init();
    initProcessing();
  }, [init, initProcessing]);

  useEffect(() => {
    if (loading) {
      svgaPlayerRef?.current?.start();
      processingPlayerRef?.current?.start();
    } else {
      svgaPlayerRef?.current?.stop();
      processingPlayerRef?.current?.stop();
    }
  }, [loading]);

  return (
    <CSSTransition
      appear
      timeout={300}
      in={loading}
      classNames={{
        appear: styles.loadingEnter,
        appearActive: styles.loadingEnterActive,
        exit: styles.loadingEnterActive,
        exitActive: styles.loadingExitActive,
      }}
    >
      <div
        className={cls(
          styles.wrapper,
          !fullscreen && styles.wrapperNode,
          loading ? maskClassName : styles.wrapperIndex,
        )}
        style={{ opacity, ...style }}
      >
        <section className={styles.loadingContainer}>
          <canvas className={styles.loading} ref={svgaRef} />
        </section>

        <section className={styles.processingContainer}>
          <canvas className={styles.processing} ref={processingRef} />
        </section>
      </div>
    </CSSTransition>
  );
};

export default NewLoading;
