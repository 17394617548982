import { useTranslation } from 'next-i18next';
import cls from 'classnames';
import avatorDefault from '@public/imgs/home-chinese-en.png';
import { ICourseInfo } from '../interface';
import styles from './styles.module.scss';
interface IProps {
  data?: Partial<ICourseInfo>;
  language?: string;
}

const Step2 = ({ data }: IProps) => {
  const { t } = useTranslation(['trailCourse']);
  return (
    <div className={cls(styles.step2MainContent, 'mobile:mt-[24px] pc:mt-[32px]')}>
      <div className={styles.left}>
        <div className={styles.avatorBox}>
          <img className={styles.avator} src={data?.teacherAvator || avatorDefault} alt="avator" />
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.name}>{data?.teacherName}</div>
        <div className={cls(styles.dialog, 'mobile:mt-[8px] mobile:p-[16px] pc:mt-[16px] pc:p-[24px]')}>
          <p>{t('取消课程-步骤二描述-1')}</p>
          <br />
          <p>{t('取消课程-步骤二描述-2')}</p>
        </div>
      </div>
    </div>
  );
};

export default Step2;
